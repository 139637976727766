<template>
	<SectionHeader :image="null" title="Neuigkeiten" page="Übersicht" />
	<div class="container">
		<div class="col-sm-12 col-lg-12">
				<div class="row">
					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 p-2" v-for="post in News" :key="post.id">
						<list-loader v-if="loading" viewBox="0 0 400 160" primaryColor="rgb(81, 207, 102)" :speed="2" :animate="true" />
						<div v-else class="card shadow-hover link-effect-box hover-box-opacity">
							<div class="position-relative">
								<router-link :to="{ name: 'Beitrag', params: { id: post.id } }">
									<img :src="getImage(post)" class="card-img-top">
								</router-link>
								<div class="position-absolute bg-success py-2 px-3 top-0 start-0 mt-3 ms-3 rounded-3 shadow text-white font-w-500 small hover-box-opacity-in">
									<i class="bi bi-calendar-date me-2"></i> {{ getDate(post) }}
								</div>
							</div>
							<div class="card-body">
								<div class="nav mb-2">
									<a class="text-reset">Neuigkeiten</a>
								</div>
								<h4 class="h5 mb-3"><router-link :to="{ name: 'Beitrag', params: { id: post.id } }" class="text-reset link-effect-in" v-text="post.title" /></h4>
								<p class="m-0"></p>
							</div>
							<div class="card-footer border-top">
								<div class="d-flex align-items-center">
									<div v-if="post.creator.picture" class="avatar avatar-sm rounded-circle mask opacity-7 bg-black">
										<img :src="getAvatar(post)">
									</div>
									<i v-else class="fad fa-2x fa-user" style="color: #51cf66;"></i>
									<div class="col ps-3 font-w-500 dark-link">
										<router-link :to="{ name: 'Mitglied', params: { id: post.creator.id } }" v-text="post.creator.name" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	</div>
</template>
<script>
import _ from 'lodash'
import SectionHeader from '@/views/SectionHeader.vue'
import { ListLoader } from 'vue-content-loader';
import moment from 'moment'
export default {
	name: 'NewsList',
	components: {
		SectionHeader,
		ListLoader
	},
	methods: {
		getImage(post)
		{
			return (post.picture) ? `https://api.bs-rt.de/assets/${post.picture.id}?key=thumbnail-300` : "";
		},
		getDate(post)
		{
			return moment(post.date_created).format('LL');
		},
	},
	created() {
		this.$store.dispatch('loadPosts');
	},
	computed: {
		News()
		{
			return _.orderBy(this.$store.state.posts.filter(q => q.category == 'news'), 'date_created', 'desc');
		},
		loading()
		{
			return this.$store.state.loadingStatus;
		}
	},
	mounted () {
		window.scrollTo(0, 0)
	}
};
</script>